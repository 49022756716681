.image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .big-image img {
    width: 100%; 
    height:500px; 
    transition: opacity 0.5s ease; 
    padding-bottom: 10px;
  }
  
  .thumbnail-images {
    display: flex;
    justify-content: center;
    gap: 10px; 
    margin-bottom: 10px;
  }
  
  .thumbnail-images img {
    width: 100px; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
    cursor: pointer;
  }
  
  .thumbnail-images img.selected {
    border: 2px solid #dbad6a; /* Highlight selected image */
  }
  