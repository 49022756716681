.store-img{
    width:100%;
    height:70vh;
}
.store-img img{
    width: 100%;
    height:100%;
}
.solid{
    border:1px solid grey;
    margin-top: -10px;

}
.store-products {
  color: #AF9058;
    background-color: rgba(45, 45, 45, 0.362);
    margin: 0 20px 0 20px;
    border: 2px solid #AF9058;
    aspect-ratio:3/2;
  }
  .store-products h5{
    font-size: 14px;
    color: #AF9058;
  }
  
  .store-product-img {
    width: 100%;
    height: fit-content;
    position: relative;
   
    object-fit: cover; /* Added this line */
  }
  
  .store-product-img img {
    width: 100%;
    height: 100%;
  }
  
  .gallery .image-container {
    position: relative;
    overflow: hidden;
  }
  
  .gallery .image-text {
    position: absolute;
    bottom: 0;
    left: 0; /* Changed from -1 to 0 */
    width: 100%; /* Changed from 92% to 100% */
    text-align: center;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .gallery .image-container:hover img {
    filter: grayscale(0%);
  }
  
  .gallery .image-container:hover .image-text {
    opacity: 1;
  }
  .image-text img{
    height:20px;
    width:20px;
   
  }
  .image-text img:hover{
    scale: 1.1;
  }
  .store-container{
    background-image: url("../../assets/images/bg22.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }