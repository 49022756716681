:root,
[data-bs-theme="light"] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #dbad6a;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #0d6efd;
  --bs-link-color-rgb: 13, 110, 253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #0a58ca;
  --bs-link-hover-color-rgb: 10, 88, 202;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;

}
* {
  padding: 0;
  margin: 0;
  
}
body{
  background-color: #141414;
  padding-top: 60px;
}
/* navbar */
.nav-link:hover{
  color: var(--bs-primary);
}
.nav-link.active {
  color:#dbad6a ;
}
.links{
  text-decoration: none ;
  color: whitesmoke;
}
h1 {
  color: white;
  font-family: "Dancing Scrip";
  font-size: 64px;
}
h3 {
  color: white;

}
h2 {
  color: white;
  font-family: "Bebas Neue";
}
a{
  text-decoration: none;
  color:white;
}
a:hover{
  color: #dbad6a;
}
h4 {
  color: #dbad6a;

}
h5{
  color:white;
}
h6{
  color:white;
}
p {
  color: #9d9e9f;
}
header {
  background-color: #00000082;
  overflow: hidden;
}
header {
  transition: background-color 0.3s ease;
}
header.scrolled {
  background-color: #1B1B1B;
}
.btn1 {
  background-color: transparent;
  border: 1px solid #dbad6a;
  font-family: "Bebas Neue";
  color: white;
}
.btn1:hover{
  background-color:#dbad6a;
  color: white;

  box-shadow: 0 0.5rem 1rem rgba(34, 34, 34, 0.938);

}
.btn2{
  background-color: #dbad6a;
  border: none;
  font-family: "Bebas Neue";
  color: white;
}
.back-video {
  width: 100%;
  height: 80vh;
}
.back-video img {
  width: 100%;
  height: 100%;
}
.aboutUs {
  margin-top: 15%;
}
.aboutUs img{
  height:80%;
}
.aboutUs-back {
  background-image: url("./assets/images/bg-2.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.why img{
  width:100%;
}
.whys img{
  width:100%;
}
.whyChooseUs{
  background-color: #141414;
}
.videsKao{
  height: 600px;
  width: 100%;
  padding:20px;
  background-color: #1B1B1B;
}
.videsKao iframe{
  height:500px;
}

/* footer */
.footer-logo{
  width:10%;
 
 }
 .social-media img{
   width:20px;
   filter: grayscale(50%);
 }
 .social-media img:hover{
   filter: grayscale(0%);
 
 }
 footer{
   background-color: #1B1B1B;
 
 
 
 
 }

 /* Blog */
 .Blogs{
  background-color: #1B1B1B;
 }
 .Blogs img{
  width:100%;

 }
 .rightBlog img{
  width:100%;
  height:30%;

 }
 .Blogs2{
  background-color: #141414;
 }
 .readMore{
  background-color: transparent;
  padding: 5px 10px;
  border:1px solid #dbad6a;
  color:white;
 }
 .readMore:hover{
  color: #dbad6a;
  border:1px solid white;

 }
 .text-process{
  width:70%;
  height:50%;
  background: #d2000037;

 }
 /* blog image slide*/
 .parent {
  width: 100%;
  background-color: transparent;
  overflow: hidden
}

.slide {
  display: inline-flex;
  width: auto;
  animation: slide-left-coins 20s linear 2s infinite
}

.slide .child {
  width: 350px;
  height: 200px;
padding: 10px;

}
.productImg{
  background-color: #141414;
}
.productImg img{

  height:80%;


}
.product{
  background-color: #1B1B1B;
}
.process-body{
  background-image: url('./assets/images/bg-2.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
@keyframes slide-left-coins {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-950px)
  }
}
/* feedback */
/* .feedback{

} 
/* process page */
.process-lft-container img{
  width:100%;

}
/* products page */
.product-img{
  height:400px;

}
.products{
  background-image: url("./assets//images//bg-2.jpg");
  width:100%;
  padding-top: 20px;
}

.product-img{
  background-color:#141414 ;
  width:70%;
  display: flex;
  align-items: center;

}
.product-detail{
  display: flex;
  justify-content: center;
  width:100%;

}
.product-rgt{
  width:50%;
  
}
.product-lft{
  width:50%;
  display: flex ;
  justify-content: end;
  
}
.product-img img{
height:80%;
}
.discriptions{
  width:100%;
  background-color: #141414;
  padding: 20px 10px;
}
.solids{
  width: 100%;
  border:1px solid rgb(77, 77, 77);
}
.text-process p{
  color:white;
  font-size: 35px;
  font-weight: 500;
  font-family: "Bebas Neue";
  
}
/* navbar */
.nav-a{
  display: flex;
}
.menu{
  display:none;
  
}
.nav-links{
  display: flex;
}
.login-register{
  display: -webkit-inline-flex;

}
.login-registers{
  display: none;
}
@media only screen and (max-width:900px) {
  .product-detail{
    display: block;
  }
  .product-rgt{
    width:100%;
  }
  .product-img{
    width:100%;
  }
  
  .product-lft{
    width: 100%;
    display: block;
  }
  h1 {
    color: white;
    font-family: "Dancing Scrip";
    font-size: 35px;
  }
  
  .aboutUs-back {
    margin-top: 14rem;
  }
  /* navbar */
  
  .nav-a {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    flex-direction: column;
    padding: 0;
    background: #141414;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
  }
  .nav-a.show {
    display: flex;
  }

  .nav-a.show {
    transform: translateX(0);
  }
  .nav-a li {
    padding: 10px 0;
  }
  .menu {
    display: block;

    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  .login-register{
    display: none;
  }
  .login-registers{
    display: block;
  }
  
}